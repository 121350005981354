<template>
  <v-card hover> <!-- @click="open(`#${'lianjie-' + cat_id + index}`)" height="244" width="590" class="ma-2" :key="item.item_id + item.title"  -->
    <v-container>
      <v-row class="justify-space-around">
        <v-col cols="12" sm="auto" class="d-flex flex-row align-content-start">
          <v-img :src="item.imgUrl" width="200" height="220" class="white--text align-end mx-auto">
            <!-- <v-card-subtitle>
              {{ item.siteName }}
            </v-card-subtitle> -->
          </v-img>
          <div>
            <v-card-subtitle>
              <span class="discount">￥<span class="price">{{ item.discountPrice ? item.discountPrice : '' }}</span></span>&nbsp;
              <span class="origin">￥<span class="price">{{ item.price ? item.price : '' }}</span></span>
              <div><span class="fanli">预计返：￥<span class="price">{{ item.fanli ? item.fanli : '' }}</span></span></div>
            </v-card-subtitle>
          </div>
        </v-col>
        <v-col cols="12" sm="auto">
          <v-card-subtitle>{{ item.title ? item.title : '' }}</v-card-subtitle>
          <v-card-subtitle>{{ item.siteName ? "[" + item.siteName + "]" : '' }}
            {{ item.shop ? item.shop : '' }}</v-card-subtitle>

          <v-row dense>
            <v-col>
              <v-text-field label="商品口令" :id="'kouling-' + cat_id + index" hide-details filled rounded color="#61a2eb"
                single-line readonly dense :value="item.kouLing" :success="true">
                <template v-slot:append>
                  <v-btn text x-small rounded @click="copy(`#${'kouling-' + cat_id + index}`)">
                    <a><v-icon>mdi-link-variant</v-icon>点击复制</a>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field label="商品链接" :id="'lianjie-' + cat_id + index" hide-details dense filled rounded
                color="#61a2eb" single-line readonly :value="item.mobileUrl">
                <template v-slot:append>
                  <v-btn text x-small rounded
                    @click="open(`#${'lianjie-' + cat_id + index}`)"><a><v-icon>mdi-link-variant</v-icon>点击访问</a></v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'ItemCard',
  props: {
    cat_id: String,
    item: Object,
    index: Number,
  },
  data: () => ({
  }),
  methods: {
    copy(id) {
      let aa = document.querySelector(id);//oInput.select();
      if (!aa) { return; }
      aa.focus();
      aa.select();
      if (!aa.value) { return; }
      document.execCommand('Copy');
      this.$toasted.show("复制成功");
    },
    open(id) {
      let aa = document.querySelector(id);
      if (!aa || !aa.value) { return; }
      window.open(aa.value, '_blank')
    },
  },
}
</script>
<style scoped>
.discount {
  color: #ff451c;
  font-weight: bold;
  font-size: 14px;
}
.discount .price {
  font-size: 18px;
}

.origin {
  font-size: 12px;
  text-decoration: line-through;
  color: #999999;
  margin-left: 0px;
}
/* .origin .price {
  font-size: 12px;
  text-decoration: line-through;
  color: #999999;
  margin-left: 0px;
} */

.fanli {
  color: #ff451c;
  font-size: 12px;
}
.fanli .price {
  /* font-weight: bold; */
  font-size: 16px;
}
</style>