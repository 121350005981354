import axios from 'axios';
import constants from './constants';
import { encrypt, decrypt } from './jsencrypt_wrapper';

axios.defaults.timeout = 10000 // 超时时间设置
axios.defaults.withCredentials = true // 是否跨域
// axios.defaults.baseURL = '/url' // 域名或ip 【注意/url和后面的vue.config.js的/url相匹配】

const jsonHeader = {
    'Content-Type': 'application/json'
};

function post({ url, data, headers, onFulfilled, onRejected }) {
    let verifyToken = encrypt(Date.now() + '');
    data.vt = verifyToken;
    axios({
        method: "POST",
        url,
        data: data,
        headers: headers ? headers : jsonHeader
    }).then(
        function (res) {
            // debugger
            if (res.data && res.data.status === constants.success && onFulfilled instanceof Function) {
                onFulfilled(res.data.data);
            }
        },
        function (res) {
            if (onRejected instanceof Function) {
                onRejected(res);
            }
            // if(res.status === constants.fail){
            //     onRejected(res);
            // }
        }
    );
}

export { post };