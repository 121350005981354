<template>
  <v-app>
    <v-system-bar app>
      <v-container>
        <!-- 仅在 xs 大小时隐藏 -->
        <span v-if="!$vuetify.breakpoint.xsOnly" style="color:#666666;">
          <a :href="$constants.url" class="main-color" style="text-decoration:none;">
            {{ $constants.site }}
          </a>
        </span>
        <!-- 仅在 xs 大小时可见 -->
        <span v-if="$vuetify.breakpoint.xsOnly">
          <a :href="$constants.url" class="main-color" style="text-decoration:none;">
            <font :color="$constants.main_color">
              {{ $constants.domain }}
            </font>
          </a>
        </span>
      </v-container>
    </v-system-bar>
    <v-app-bar app elevation="0" dense>
      <template v-slot>
        <v-container>
          <v-row no-gutters>
            <v-spacer></v-spacer>

            <!-- logo -->
            <v-col class="d-none d-sm-flex col-sm-2" style="min-width: 90px;">
              <a :href="$constants.url" class="main-color"
                style="text-decoration:none;font-weight: bold; font-size: x-large;">
                {{ $constants.domain }}
              </a>
            </v-col>
            <!-- ./logo -->

            <!-- 搜索栏 -->
            <v-col class="col-10 col-sm-5" style="min-width: 100px;">
              <v-text-field type="search" label="请输入要查找的商品/链接" v-model="keyword" dense single-line solo flat :hide-details="true"
                clearable @keyup.enter.native="search()"></v-text-field>
            </v-col>
            <v-col class="col-2 col-sm-2">
              <v-btn :color="$constants.main_color" style="color:#ffffff;" elevation="0" @click="search()">搜索</v-btn>
            </v-col>
            <!-- ./搜索栏 -->

            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </template>
      <!-- tab页签 -->
      <!-- <template v-slot:extension>
        <v-tabs centered center-active show-arrows optional :color="$constants.main_color"
          :slider-color="$constants.main_color" v-model="tab">
          <v-tab v-for="cat in $store.state.catagory" :key="`tab_${cat.cat_id}`" :href="`#${cat.cat_id}`"
            @click="goto(`#${cat.cat_id}`)" replace>
            {{ cat.cat_name }}
          </v-tab>
        </v-tabs>
      </template> -->
      <!-- ./tab页签 -->
    </v-app-bar>

    <v-main class="width-set mx-auto">
      <div :style="wrapperStyleCompute" class="main-wrapper" v-scroll.self="onAnchorScroll">

        <!-- 跳转至顶部按钮 -->
        <v-btn v-show="showToTopBtn" elevation="1" fab tile fixed small bottom right plain @click="goto(0);"
          class="mr-2" style="color: #666;background-color: #eee;">
          <v-icon>mdi-format-vertical-align-top</v-icon>
        </v-btn>
        <!-- ./跳转至顶部按钮 -->

        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { post } from './assets/js/axios_wrapper';
import { RouterView } from 'vue-router';
import router from './router';

export default {
  name: 'App',
  data: () => ({
    showToTopBtn: false,
    isIntersecting: false,
    showToTopBtnOffset: 400,

    keyword: "",

    tab: "",

    goToOptions: {
      duration: 300,
      // offset: 0,
      // easing: 'easeInOutCubic',
      container: '.main-wrapper',
    },

  }),
  computed: {
    wrapperStyleCompute() {
      return "height: calc( 100vh - " + this.$vuetify.application.bar + "px - " + this.$vuetify.application.top + "px )";
    },
  },
  components: {},
  watch: {
    // tab: function (newVal, oldVal) {
    //   console.log("====tab====", newVal, oldVal);
    // }
  },
  created() {
    this.$store.state.catagory = [
      { cat_name: '精选好货', cat_id: 'jingXuan', cat_order: '1' },
      { cat_name: '家居用品', cat_id: 'jiaJu', cat_order: '2' },
      { cat_name: '女装', cat_id: 'nvZhuang', cat_order: '3' },
    ]
  },
  mounted() {
    // console.log("=====this.$vuetify.scrollBarWidth=====", this.$vuetify.scrollBarWidth);
    this.$vuetify.scrollBarWidth -= 20;
  },
  methods: {
    //锚点跳转
    goto(target) {
      this.$vuetify.goTo(target, this.goToOptions);
    },
    onIntersect(entries, observer, isIntersecting) {
      console.log("===123===", entries, observer, isIntersecting);
      console.log("===entries[0].target.id===", entries[0].target.id);
      if (isIntersecting && entries[0].target.id && this.tab !== entries[0].target.id) {
        this.tab = entries[0].target.id;
      }
      // debugger
      // this.$toasted.show(123);
      // this.isIntersecting = isIntersecting;
    },
    onAnchorScroll(e) {
      if (this.showToTopBtn === false && e.target.scrollTop >= this.showToTopBtnOffset) {
        this.showToTopBtn = true;
      } else if (this.showToTopBtn === true && e.target.scrollTop < this.showToTopBtnOffset) {
        this.showToTopBtn = false;
      }
    },
    search() {
      let _this = this;
      post({
        url: '/m/s',
        data: { 'keyword': this.keyword },
        onFulfilled: function (res) {
          // console.log("=====res=====", res);
          // console.log("=====_this.$route=====", _this.$route);
          _this.$store.state.search_result = res;
          if (_this.$route.name !== 'search_result') {
            router.push('/search_result');
          }
        }
      });
    },
  },
};
</script>

<style scoped>
/* .wrapper {
  height: 100vh;
  width: 100vw;
  overflow: auto;
  display: flexbox;
} */
.main-wrapper {
  /* overflow: auto; */
}

.width-set {
  min-width: 350px;
  max-width: 1200px;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>