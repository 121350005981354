import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import toasted from 'vue-toasted';

import '@/assets/css/global.css'; //引用全局css
import constants from '@/assets/js/constants.js'

Vue.config.productionTip = false;
Vue.prototype.$constants = constants;

Vue.use(toasted, {
  singleton: true,
  duration: 1000,
  position: 'bottom-center',
  // theme: 'outline'
  // iconPack : 'material' // set your iconPack, defaults to material. material|fontawesome|custom-class
  containerClass: 'toasted-container-global',
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
