const { default: JSEncrypt } = require("jsencrypt")
const { default: constants } = require("./constants")

// import JSEncrypt from './jsencrypt.js'
// 加密
function encrypt(data) {
    // let publicKey = constants.rsa_pkcs1_publickey; //把之前生成的贴进来，实际开发过程中，可以是后台传过来的
    let publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCk/rMfFKtQW51ST8v/nXjgR876w7Vq/+8S9fE7MJPovL8QeqOLnVLNr+ERTCAuq8KF/E5DTcGW4e1NRLN8Jvo2ccKopNOh4x8OxzEKofhc/jHnJR/wljyb4uoIOU+5YRc49bBsOTNfiIaMh/QbrK/YpuAuwAFjjUu5xgug98TwBQIDAQAB';
    let encryptor = new JSEncrypt(); // 新建JSEncrypt对象
    encryptor.setPublicKey(publicKey); // 设置公钥
    // 对需要加密的数据进行加密
    return encryptor.encrypt(data);
}

// 解密
function decrypt(data) {
    let privateKey = 'MIICXgIBAAKBgQCk/rMfFKtQW51ST8v/nXjgR876w7Vq/+8S9fE7MJPovL8QeqOLnVLNr+ERTCAuq8KF/E5DTcGW4e1NRLN8Jvo2ccKopNOh4x8OxzEKofhc/jHnJR/wljyb4uoIOU+5YRc49bBsOTNfiIaMh/QbrK/YpuAuwAFjjUu5xgug98TwBQIDAQABAoGBAJ1YDzVanqy5UWQHmQEhCP7JPAdDz6REgZdRV0Uc5FCUfpiWe5RO6YIhcb7vG9mmDpF7tJ7WbgEXXxfKpmeFPCJar6LRY51/Limyfm5Zg7Cso2htShLfVpttNrk+yHgd/G9rOiq2/ErCXGb6v+uiovhH2nFQ9Ohy+vwUIlgKxwuBAkEA24W4bnuRG6V8TozwmhU/Pkr9ELv9JCGpF0vvsz8xPMmMNRwEebsTAAvdyodA4CiRttJNQxA70MwEHRlwllyYlQJBAMBpbrq4kzBFjk6QplkdAIB8QURcvbkorO64havVAuanhZQIKYPJAIGZ5dYc7ezzb49M9vIwUesyc8XvzeeobbECQAH57DUia6YCEWJrpmbcthsLdcfGyXqfyvPovRmyAfF4Jwj7XZby9jLT6IVAPndGJjmtHwDxjnhMveS9K+ROpRkCQQC3nyZz/RdCVu7C3VpS6tGCzJHAury4SjAWtXOjk2nsUuywY8WypYzdIdBsMDmDCqJpYCq+4AwdZ42C1SJsNJlRAkEAh7OJjR7hw+63ImQyORx1SGf9DwsKvbf4yXkCD9UYh1iTO2+RekCzLpC/H/ZN1uWmsA+ToOCGMGmjy/MbdLrsng==';
    // let privateKey = `-----BEGIN RSA PRIVATE KEY-----
    // MIICXgIBAAKBgQCk/rMfFKtQW51ST8v/nXjgR876w7Vq/+8S9fE7MJPovL8QeqOL
    // nVLNr+ERTCAuq8KF/E5DTcGW4e1NRLN8Jvo2ccKopNOh4x8OxzEKofhc/jHnJR/w
    // ljyb4uoIOU+5YRc49bBsOTNfiIaMh/QbrK/YpuAuwAFjjUu5xgug98TwBQIDAQAB
    // AoGBAJ1YDzVanqy5UWQHmQEhCP7JPAdDz6REgZdRV0Uc5FCUfpiWe5RO6YIhcb7v
    // G9mmDpF7tJ7WbgEXXxfKpmeFPCJar6LRY51/Limyfm5Zg7Cso2htShLfVpttNrk+
    // yHgd/G9rOiq2/ErCXGb6v+uiovhH2nFQ9Ohy+vwUIlgKxwuBAkEA24W4bnuRG6V8
    // TozwmhU/Pkr9ELv9JCGpF0vvsz8xPMmMNRwEebsTAAvdyodA4CiRttJNQxA70MwE
    // HRlwllyYlQJBAMBpbrq4kzBFjk6QplkdAIB8QURcvbkorO64havVAuanhZQIKYPJ
    // AIGZ5dYc7ezzb49M9vIwUesyc8XvzeeobbECQAH57DUia6YCEWJrpmbcthsLdcfG
    // yXqfyvPovRmyAfF4Jwj7XZby9jLT6IVAPndGJjmtHwDxjnhMveS9K+ROpRkCQQC3
    // nyZz/RdCVu7C3VpS6tGCzJHAury4SjAWtXOjk2nsUuywY8WypYzdIdBsMDmDCqJp
    // YCq+4AwdZ42C1SJsNJlRAkEAh7OJjR7hw+63ImQyORx1SGf9DwsKvbf4yXkCD9UY
    // h1iTO2+RekCzLpC/H/ZN1uWmsA+ToOCGMGmjy/MbdLrsng==
    // -----END RSA PRIVATE KEY-----`;
    let decrypt = new JSEncrypt();
    // 设置私钥
    decrypt.setPrivateKey(privateKey);
    return decrypt.decrypt(data);
}

module.exports = {
    encrypt,
    decrypt
}