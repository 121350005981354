<template>
  <v-container>
    <v-row>
      <v-col cols="12">

        <!-- 轮播 -->
        <v-carousel v-if="(showActivityCarousel && activityArray.length > 0)" cycle :height="carouselHeight"
          show-arrows-on-hover progress :progress-color="this.$constants.main_color" hide-delimiters
          style="width: 100vw;">
          <v-carousel-item v-for="(a, i) in activityArray" :src="a.src" :key="i"></v-carousel-item>
        </v-carousel>
        <!-- ./轮播 -->

        <!-- 主内容区域 懒加载 -->
        <v-lazy :options="{
          threshold: 0.5
        }" transition="fade-transition" v-for="cat in $store.state.catagory" :key="cat.cat_id">
          <!-- 
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: 0.5
              }
            }"
          -->
          <v-sheet :id="cat.cat_id" outlined class="mt-2 py-6 px-4">
            <v-card-title v-text="cat.cat_name"></v-card-title>
            <!-- <v-container> -->
            <v-row class="justify-space-between">
              <template v-for="(item, i) in item_list">
                <v-col class="col-12 col-sm-6" :key="i">
                  <ItemCard :item="item" :cat_id="cat.cat_id"/>
                </v-col>
                <!-- <v-spacer></v-spacer> -->
              </template>
            </v-row>
            <!-- </v-container> -->
          </v-sheet>
        </v-lazy>

        <!-- footer -->
        <v-row justify="center" no-gutters>
          <v-col class="lighten-2 py-4 text-center" cols="12">
            <span style="font-size: 12px; color: #999;">Copyright © {{ $constants.copyright_time }} {{
                $constants.site_name
            }} 版权所有&nbsp;
              <a href="http://beian.miit.gov.cn" rel="nofollow">{{ $constants.icp }}</a></span>
          </v-col>
        </v-row>
        <!-- ./footer -->

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ItemCard from '@/components/ItemCard.vue';

export default {
  name: 'Index',
  data: () => ({
    showActivityCarousel: true,
    carouselHeight: 400,

    activityArray: [],
    // catagory: [],
    item_list: [],

  }),
  components: {
    ItemCard
},
  created() {
    this.activityArray = [
      { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg' },
      { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg' },
      { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg' },
    ],

      this.item_list = [
        {
          "site": "淘宝",
          "category_id": 201648602,
          "category_name": "派克服",
          "commission_rate": "330",
          "commission_type": "ZX",
          "coupon_amount": "50",
          "coupon_end_time": "2022-12-14 23:59:59",
          "coupon_id": "1ab78be5cd7d4d58aeef2c0dc6b05367",
          "coupon_info": "满500元减50元",
          "coupon_remain_count": 1045,
          "coupon_share_url": "//uland.taobao.com/coupon/edetail?e=Y%2BsVK20AFTINfLV8niU3R5TgU2jJNKOfNNtsjZw%2F%2FoIolUSdWzek8OfIw3SU3BPvQJK%2FV3g%2F5HTSuw0Thjh2W0gfTscre%2FnAy5FOJP2gSFULsAsCVtiWzqgzsDsrqTByQ%2FtUOWYrnW5hgEx7uOvhc7EE3WGyF2QyqU3wEbT6Emtf2NsWnsLCPcvC1hnzXoEL0CjtNkYqrwlZE%2Frq7N6jtg%3D%3D&&app_pvid=59590_33.61.71.185_886_1668794152511&ptl=floorId:6268;app_pvid:59590_33.61.71.185_886_1668794152511;tpp_pvid:be6a01f5-5c1a-4419-8512-daabd9589864&union_lens=lensId%3AMAPI%401668794152%40213d47b9_0b67_1848be1f6f9_a475%4001",
          "coupon_start_fee": "500",
          "coupon_start_time": "2022-10-16 00:00:00",
          "coupon_total_count": 1111,
          "include_dxjh": "false",
          "include_mkt": "false",
          "info_dxjh": "{}",
          "item_description": "",
          "item_id": "691852626572",
          "item_url": "https://item.taobao.com/item.htm?id=691852626572",
          "level_one_category_id": 16,
          "level_one_category_name": "女装/女士精品",
          "nick": "西溪里购全球",
          "num_iid": "691852626572",
          "pict_url": "https://img.alicdn.com/bao/uploaded/i4/2200740114294/O1CN018jkdTn1hahV03Eu4o_!!2200740114294.jpg",
          "provcity": "浙江 杭州",
          "real_post_fee": "0.00",
          "reserve_price": "1582",
          "seller_id": 189152438883745337,
          "shop_dsr": 0,
          "shop_title": "西溪里购全球",
          "title": "sandro moscoloni2022秋冬季新款连帽羽绒棉服长款气质宽松棉衣女",
          "tk_total_commi": "0",
          "tk_total_sales": "0",
          "url": "//s.click.taobao.com/t?e=m%3D2%26s%3D0YofRDmUpX8cQipKwQzePOeEDrYVVa64juWlisr3dOdyINtkUhsv0FEERyiPxZH1r%2Bjx9twMZJkR%2B%2B8YMKqcKce6WclAt8RFkBmqoV0KHFJT1c%2FyJqbBKIf1a%2FVbwdBbFBoMXOGuG5DkaqczTKGnOsNkBq8q%2FLGGC5NF5rBK3%2Fajz%2BP9CNAHqffFP1JmL7ilyZhvfEnuh9fGDF1NzTQoPw%3D%3D&union_lens=lensId%3AMAPI%401668794152%40213d47b9_0b67_1848be1f6f9_a475%4001",
          "user_type": 0,
          "volume": 0,
          "white_image": "",
          "zk_final_price": "791"
        },
        {
          "site": "淘宝",
          "category_id": 201648602,
          "category_name": "派克服",
          "commission_rate": "330",
          "commission_type": "ZX",
          "coupon_amount": "50",
          "coupon_end_time": "2022-12-14 23:59:59",
          "coupon_id": "1ab78be5cd7d4d58aeef2c0dc6b05367",
          "coupon_info": "满500元减50元",
          "coupon_remain_count": 1045,
          "coupon_share_url": "//uland.taobao.com/coupon/edetail?e=Y%2BsVK20AFTINfLV8niU3R5TgU2jJNKOfNNtsjZw%2F%2FoIolUSdWzek8OfIw3SU3BPvQJK%2FV3g%2F5HTSuw0Thjh2W0gfTscre%2FnAy5FOJP2gSFULsAsCVtiWzqgzsDsrqTByQ%2FtUOWYrnW5hgEx7uOvhc7EE3WGyF2QyqU3wEbT6Emtf2NsWnsLCPcvC1hnzXoEL0CjtNkYqrwlZE%2Frq7N6jtg%3D%3D&&app_pvid=59590_33.61.71.185_886_1668794152511&ptl=floorId:6268;app_pvid:59590_33.61.71.185_886_1668794152511;tpp_pvid:be6a01f5-5c1a-4419-8512-daabd9589864&union_lens=lensId%3AMAPI%401668794152%40213d47b9_0b67_1848be1f6f9_a475%4001",
          "coupon_start_fee": "500",
          "coupon_start_time": "2022-10-16 00:00:00",
          "coupon_total_count": 1111,
          "include_dxjh": "false",
          "include_mkt": "false",
          "info_dxjh": "{}",
          "item_description": "",
          "item_id": "691852626572",
          "item_url": "https://item.taobao.com/item.htm?id=691852626572",
          "level_one_category_id": 16,
          "level_one_category_name": "女装/女士精品",
          "nick": "西溪里购全球",
          "num_iid": "691852626572",
          "pict_url": "https://img.alicdn.com/bao/uploaded/i4/2200740114294/O1CN018jkdTn1hahV03Eu4o_!!2200740114294.jpg",
          "provcity": "浙江 杭州",
          "real_post_fee": "0.00",
          "reserve_price": "1582",
          "seller_id": 189152438883745337,
          "shop_dsr": 0,
          "shop_title": "西溪里购全球",
          "title": "sandro moscoloni2022秋冬季新款连帽羽绒棉服长款气质宽松棉衣女",
          "tk_total_commi": "0",
          "tk_total_sales": "0",
          "url": "//s.click.taobao.com/t?e=m%3D2%26s%3D0YofRDmUpX8cQipKwQzePOeEDrYVVa64juWlisr3dOdyINtkUhsv0FEERyiPxZH1r%2Bjx9twMZJkR%2B%2B8YMKqcKce6WclAt8RFkBmqoV0KHFJT1c%2FyJqbBKIf1a%2FVbwdBbFBoMXOGuG5DkaqczTKGnOsNkBq8q%2FLGGC5NF5rBK3%2Fajz%2BP9CNAHqffFP1JmL7ilyZhvfEnuh9fGDF1NzTQoPw%3D%3D&union_lens=lensId%3AMAPI%401668794152%40213d47b9_0b67_1848be1f6f9_a475%4001",
          "user_type": 0,
          "volume": 0,
          "white_image": "",
          "zk_final_price": "791"
        }
      ]
  },
}
</script>
